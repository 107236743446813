<template>
    <div class="clientcabin clientcabin-blog">
        <v-container class="black--text">
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-10">
                    <p class="text-md-h3 text-h4 mt-8 text-center">
                        Online Medical Store Generates Millions in Sales From
                        Just 113 Pieces of Content in Less Than 12 Months
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/1.png"
                        width="100%"
                    />
                    <p class="text-h4 mt-8 text-center">
                        Shopify Store in Multi-Billion Dollar Market Had No
                        Search Traffic
                    </p>

                    <p>
                        This online medical store had only 561 organic visitors
                        (cumulative 2021) to their Shopify store for a lung
                        cleansing device… which is kind of non-existent traffic.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/2.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Clearly, this is a niche product. Without a solid
                        marketing strategy, the business would not have been
                        able to gain visibility in a crowded market.
                    </p>
                    <p>
                        The market for these devices is huge… in fact, it’s set
                        to reach $51.6 billion in 2027 as they’re essential for
                        many people. Approximately 100-200 million dollars are
                        spent annually on these specific devices.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/3.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Source: Alliedmarketresearch.com and
                        futuremarketinginsigths.com
                    </p>
                    <p>
                        Besides, there is a lot of competition around it,
                        regardless of what it may be called (a lung cleaner),
                        what it does (clean the lungs), or the problem it solves
                        (mucus filled lungs).
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Hyper-Targeted Content Was Published All Over The
                        Internet And Added $47,762 In Sales In Just 6 Weeks
                    </p>
                    <p>
                        To improve this online medical store’s online
                        visibility, recurring campaigns were used.
                    </p>
                    <p>
                        The video from the first campaign for the Shopify store
                        went live on August 22nd, 2021 while the news article
                        went live on September 8th, allowing time to measure
                        results before releasing any more content.
                    </p>
                    <p>
                        Here, the promoted business is in direct competition
                        with Amazon, which is a pretty tough competitor.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/4.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Despite the tough competition, guess who's number one
                        and just below Amazon? That's right—it's the published
                        campaign for the medical device!
                    </p>
                    <p>
                        Content amplification campaigns got the online medical
                        store ranked in Google and pushed into the first page
                        rankings.
                    </p>
                    <p>
                        We’ve seen this kind of success before with other
                        clients who have used the same strategy: run news
                        announcements to your videos, then point them toward the
                        main product page.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Organic Website Traffic Went From 1,500 Uniques to over
                        10,000/mo in 8 Weeks and Sales Increased by 51%
                    </p>
                    <p>
                        By the end of August 2021, the Shopify store had
                        increased their traffic from 1500 or 1600 organic
                        visitors per month to over 7,000 unique organic visitors
                        per month! That’s more than four times what the store
                        was getting before they started the campaigns.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/5.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        This case study deserves to be on top of the industry
                        leaderboards - take a look at the traffic generated just
                        by publishing a lot of quality content.
                    </p>
                    <p>
                        As the graph shows, the campaigns generated impressive
                        growth in organic traffic.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/6.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        Campaigns were published back around September and
                        October, and you can see how quickly traffic shot up
                        after that.
                    </p>
                    <p>
                        The graph shows an estimate of the organic traffic
                        growth throughout the course of this campaign, but you
                        can see that this is an underestimate because by
                        October, they’d already got to 10,000 visitors a month,
                        all organic traffic.
                    </p>
                    <p>
                        1 year later by June their traffic had increased
                        approximately 20,000% (a 20x increase in targeted search
                        traffic). This has taken them from approx. $48,000 in
                        sales per month, to $735,000 in sales per month…
                        estimated to be an extra $8,300,000 in yearly sales!
                    </p>
                    <p>
                        This online medical store was getting a huge spread
                        across the web and was becoming a pretty well-known
                        brand in their industry. They are in the top 10% of that
                        particular market.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/7.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        The online medical store started ranking for hundreds of
                        different terms because the published content is so
                        specific.
                    </p>
                    <p>
                        What is more, these campaigns got the product featured
                        in a story that was picked up by major news
                        organizations and a niche news site.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/8.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        The page on which the product has been advertised has
                        been getting organic traffic during the last months of
                        2021, and it's still bringing in visitors.
                    </p>
                    <p>
                        This eventually led to the online medical store
                        converting more sales (51% increase!) as they became
                        more visible to potential customers.
                    </p>
                    <v-img
                        class="rounded-lg"
                        src="@/assets/img/clientcabin/stories/online-medical-store/9.png"
                        width="100%"
                    />
                    <p class="text-body-2 font-italic mt-2 px-4 text-center">
                        The store sales through search were up by 51% as of the
                        12th of October, 2021.
                    </p>
                    <p class="text-h4 mt-8 text-center">
                        Our Campaigns Are Proven To Slingshot eCom Sales For Any
                        Industry
                    </p>
                    <p>
                        If you’re going to compete against some of the largest
                        online eCom players, it’s time that you consider taking
                        your content marketing to the next level with
                        {{ company }}. Proper planning and execution with our
                        content specialists will only increase the chances for
                        success; without good content, and great distribution of
                        that content, your results will be lacklustre.
                    </p>
                    <p>
                        {{ company }} ranks your product above the competition
                        and turbocharges your visibility online, where it’s
                        easier for leads to reach you. By increasing your
                        organic traffic through our content amplification
                        engine, while keeping the cost low, we give you the best
                        of both worlds.
                    </p>
                    <p>
                        Discover how you can save time and money with us and get
                        found more easily by your target segment looking for
                        products just like yours.
                    </p>
                    <p>
                        <router-link
                            to="/info"
                            class="d-inline-block font-weight-bold"
                        >
                            Book an appointment with our team of experts.
                        </router-link>
                    </p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class OnlineMedicalStore extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-blog::v-deep {
    background-color: $white;
    color: $black;
}
</style>
